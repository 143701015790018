.container {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-end;
  margin-top: 1em;
}

.container > *:not(first-child) {
  margin-left: 0.5em;
}
