.header {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  margin-bottom: 1em;
}

.headerUsersLabel {
  margin-top: 0.25em;
  font-size: 0.9em;
}

.usersList {
  display: flex;
  flex-flow: column nowrap;
  list-style: none;
}

.usersList > li {
  border-bottom: 1px solid var(--color-border);
  padding: 0.5em 0;
}
