.rolesList {
  list-style: none;
}

.rolesList > li {
  padding: 0.5em 0;
  border-bottom: 1px solid var(--color-border);
}

.rolesList > li:first-child {
  padding-top: 0;
}

.rolesList > li:last-child {
  padding-bottom: 0;
  border-bottom: none;
}

.roleCreate {
  margin-top: 1em;
}
