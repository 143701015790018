.container {
  width: 100%;
  padding: 0.7em 0.8em;
  border-radius: 3px;
}

.danger {
  background-color: #ce1c1c;
  border-left: 7px solid #911515;
  color: #fff;
}

.success {
  background-color: #058d42;
  border-left: 7px solid #3a5800;
  color: #fff;
}

.warning {
  background-color: #fdc36c;
  border-left: 7px solid #b46c00;
  color: #000;
}

.info {
  background-color: #3f79e6;
  border-left: 7px solid #295ab4;
  color: #fff;
}

.title {
  font-size: 1.1em;
  font-weight: bold;
  margin-bottom: 0.2em;
}

.message {
  font-size: 1em;
  font-weight: normal;
}
