.input {
  background-color: var(--color-input-bg);
  color: var(--color-input-text);
  border: 3px solid var(--color-input-border);
  border-radius: 8px;
  height: 36px;
  line-height: 30px;
  padding: 0 0.5em;
  
  font-size: 1em;
  box-shadow: none;
  outline: none;
  width: 100%;
}

.input::placeholder {
  color: var(--color-input-placeholder);
}

.input:focus {
  border-color: var(--color-input-border-focus);
}

.input:invalid:not(.fresh) {
  border-color: var(--color-input-border-invalid);
}

.input:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
