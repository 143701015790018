*,
*:before,
*:after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  
  

  background-color: var(--color-bg);
  color: var(--color-text);
}

body, span, div, input, button, a, textarea, pre,
abbr, address, area, article, aside, b, base,
blockquote, canvas, caption, cite, code, col,
colgroup, data, datalist, dd, del, details, dialog,
dl, dt, em, embed, fieldset, figcaption, figure, footer,
form, h1, h2, h3, h4, h5, h6, head, header, i, iframe,
ins, label, legend, li, link, main, mark, nav, noscript,
object, ol, optgroup, option, output, p, param, q, section,
select, small, source, strong, sub, summary, sup, table,
tbody, td, template, tfoot, th, thead, title, tr,
u, ul
{
  font-family: "Open Sans", "IBM Plex Sans", "Helvetica Neue", sans-serif; /*var(--font-family);*/
}

code {
  /* font-family: var(--font-family-mono), source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;*/
  position: inline;
  padding: 0.1em 0.4em;
  border-radius: 4px;
  width: min-content;
}

#root {
  height: 100vh;
}
