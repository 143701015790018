.page {
  height: 100vh;
  display: flex;
  flex-flow: row nowrap;
  justify-content: stretch;
}

.content {
  padding: 1em;
  overflow-y: auto;
  flex: 1 1 100%;
}

.nav {
  flex: 0 0 260px;
  background-color: var(--color-nav-sidebar-bg);
  border-right: 1px solid var(--color-border);
  display: flex;
  flex-flow: column nowrap;

  --collapse-size: 44px;
  --text-color: var(--color-text);
  --highlight-color: var(--color-surface-yellow);
  --highlight-text-color: #000;
}

.nav.collapsed {
  width: var(--collapse-size);
  flex: 0 0 var(--collapse-size);
}

/*=========================*\
||     Navigation Items    ||
\*=========================*/

.list {
  list-style: none;
  flex: 1 1 100%;
}

.collapsed .list {
  padding: 0.5em 3px;
}

/*=========================*\
||         NavGroup        ||
\*=========================*/

.group {
  padding: 0.5em 0 0.75em 0;
}

.collapsed .group {
  padding: 3px 0;
  padding-bottom: 0.5em;
  margin-bottom: 0.5em;
}

.collapsed .group:not(:last-child) {
  border-bottom: 1px solid var(--color-border);
}

.group + .group {
  padding-top: 0;
}

.group > header {
  padding-bottom: 0.5em;
  border-bottom: 1px solid var(--color-border);
  margin: 0.5em 0.75em;
}

.collapsed .group > header {
  display: none;
}

.group > header > span {
  color: var(--text-color);
  font-weight: bold;
}

.groupList {
  list-style: none;
}

/*=========================*\
||         NavItem         ||
\*=========================*/

.item {
  border-radius: 0;
}

.collapsed .item {
  border-radius: 3px;
  height: calc(var(--collapse-size) - 6px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.item.active {
  background-color: var(--highlight-color);
}

.item.active .itemLink {
  color: var(--highlight-text-color);
}

.itemLink {
  padding: 0.5em 0.75em;
  color: var(--text-color);
  display: flex;
  align-items: center;
  text-decoration: none;
}

.collapsed .itemLink {
  display: flex;
  padding: 0;
  width: var(--collapse-size);
  height: var(--collapse-size);
  align-items: center;
  justify-content: center;
}

.itemIcon {
  margin-right: 0.5em;
  display: flex;
  align-items: center;
  justify-items: center;
  pointer-events: none;
}

.collapsed .itemIcon {
  margin-right: 0;
}

.itemIcon > svg {
  width: 20px;
  height: 20px;
  stroke-width: 1.5;
}

.collapsed .itemIcon > svg {
  width: 24px;
  height: 24px;
  stroke-width: 1.3;
}

/*=========================*\
||      Collapse Toggle    ||
\*=========================*/

.collapseToggle {
  height: 50px;
  width: 100%;
  background-color: transparent;
  border: none;
  outline: none;
  border-top: 1px solid var(--color-border);
  color: var(--color-text);
  font-size: inherit;
  
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  position: relative;
}

.collapseToggle:hover:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.1;
  background-color: var(--color-text);
}

.collapsed .collapseToggle {
  justify-content: center;
}

.collapseIcon {
  display: block;
  position: relative;
  max-width: var(--collapse-size);
  width: 100%;
  height: 50px;
  padding: 14px 0;
  transform: rotate(180deg);
}

.collapsed .collapseIcon {
  transform: rotate(0deg);
}

.collapsed .collapseLabel {
  display: none;
}
