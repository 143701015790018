.link {
  text-decoration: none;
  font-weight: normal;

  color: var(--color-text);
  background-color: var(--color-link-bg);
  border-bottom: 1px solid var(--color-link-border);
}

.link:hover {
  background-color: var(--color-link-bg-hover);
  border-color: var(--color-link-border-hover);
}

.link:visited {
  background-color: var(--color-link-bg-visited);
  border-color: var(--color-link-border-visited);
}

.link:visited:hover {
  background-color: var(--color-link-bg-visited-hover);
  border-color: var(--color-link-border-visited-hover);
}
