.badge {
  display: inline-flex;
  background-color: var(--color-surface-cyan);
  align-items: center;
  padding: 0 0.25em;
  border-radius: 6px;
  color: var(--color-text-inverted);
}

.badge.small {
  font-size: 0.9em;
}

.badge.tiny {
  font-size: 0.7em;
}

.badge.gray {
  background-color: var(--color-surface-gray);
}
