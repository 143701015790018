.input {
  width: 100%;
}

.listItem {
  color: var(--color-text);
  appearance: none;
  background-color: transparent;
  outline: none;
  border: none;
  font-size: 1em;
  
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;

  border-bottom: 1px solid var(--color-border);
  padding: 0.4em 0;
}

.listItem:last-child {
  border-bottom: none;
  margin-bottom: 0.25em;
}

.listItemKey {
}

.listItemDescription {
}
