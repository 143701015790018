.container {
  position: relative;
  width: 100%;
  background-color: transparent;
  overflow-x: auto;
  /* overflow-y: auto; */
}

.container.scrollable {
  overflow-y: auto;
  overflow-x: hidden;
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.table thead th {
  position: relative;
  text-align: left;
  border-bottom: 3px solid var(--color-border);
  padding: 0.25em 1em;
  background-color: var(--color-table-header-bg);
  white-space: nowrap;
}

.table td {
  padding: 0.25em 1em;
}

.table tbody tr:nth-child(even) {
  background-color: var(--color-table-row-even-bg);
}

.scrollable thead th {
  position: sticky;
  top: 0;
}

.styledTable {
  background-color: rgb(1, 1, 59);
  width: 50%;
  margin: auto;
}