.container {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  width: 800px;
}

.loader {
  height: 40px;
  width: 80px;
  margin-bottom: 1em;
}

.label {
  font-size: 1.2em;
  font-weight: bold;
}

.subLabel {
  opacity: 0.7;
  font-size: 1em;
  margin-top: 0.5em;
  height: 30px;
  width: 100%;
  position: relative;
}

.subLabelText {
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  display: block;
  transform: translate(-50%, -50%);
  transition: opacity 240ms cubic-bezier(0.22, 1, 0.36, 1),
    transform 240ms cubic-bezier(0.22, 1, 0.36, 1);
}

/* Label transition group classes */
.enter {
  opacity: 0;
  transform: translate(-50%, -50%);
}
.enterActive {
  opacity: 0;
  transform: translate(-50%, calc(-50% + 10px));
}
.enterDone {
  opacity: 1;
  transform: translate(-50%, -50%);
}

.exit {
  transform: translate(-50%, -50%);
  opacity: 0;
}
.exitActive {
  transform: translate(-50%, calc(-50% - 10px));
  opacity: 0;
}
.exitDone {
  opacity: 1;
  transform: translate(-50%, calc(-50% - 10px));
}
