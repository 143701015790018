.container {
  padding: 2em;
  background-color: #1f52f7;
  color: #fff;
  height: 100%;
}

.container > h1 {
  margin-bottom: 0.5rem;
}

.container > hr {
  color: #fff;
  margin-bottom: 1rem;
}

.messageLabel {
  font-size: 0.9em;
  margin-bottom: 0.2em;
}

.message {
  margin-bottom: 1em;
  /* border: 1px solid #fff; */
  background-color: #173bb1;
  color: #fff;
  padding: 0.5em;
  border-radius: 4px;
}

.footerText {
}
