.container {
}

.header {
  margin-bottom: 1em;
}

.container > section {
  margin-bottom: 1em;
  border: 3px solid var(--color-border);
  border-radius: 12px;
  padding: 1em;
  background-color: var(--color-card-bg);
}

section > header {
  margin-bottom: 0.5em;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}

.nameInput {
  font-size: 2em;
}

.addUser,
.addPermission {
  display: flex;
  align-items: center;
  margin-top: 1em;
  width: 100%;
}

.addUser > *:not(:last-child),
.addPermission > *:not(:last-child) {
  margin-right: 0.25em;
}

.permissionsList {
  list-style: none;
}

.permissionsList > li {
  display: flex;
  align-items: center;

  padding: 0.25em 0;
}

.permissionsList > li:not(:last-child) {
  border-bottom: 1px solid var(--color-border);
}

.usersList {
  list-style: none;
}

.usersList > li {
  display: flex;
  align-items: center;

  padding: 0.25em 0;
}

.usersList > li:not(:last-child) {
  border-bottom: 1px solid var(--color-border);
}

.removeButton {
  margin-right: 1em;
}

.fullWidthInput {
  width: 100%;
}
