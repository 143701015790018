.container {
  flex: 1 1 100%;
  position: relative;
  height: 36px;
  width: 100%;
}

.input {
  width: 100%;
  position: absolute;
  z-index: 100;
}

.suggestionPanel {
  border: 3px solid var(--color-border-faded);
  background-color: var(--color-input-bg);
  position: absolute;
  width: 100%;
  top: calc(100% - 9px);
  padding-top: 7px;
  padding-bottom: 0.25em;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;

  overflow-y: auto;
  max-height: 400px;

  display: none;
  z-index: 99;
}

.suggestionPanel.open {
  display: block;
}

.statusLabel {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  padding: 0em 0;
  font-size: 0.9em;
  color: var(--color-text-gray);
  background-color: var(--color-input-bg);
  padding: 0.2em 0.5em 0.3em 0.5em;

  display: flex;
  align-items: center;
}

.statusLoader {
  margin-right: 0.2em;
}

.suggestions {
  display: flex;
  flex-flow: column nowrap;
  padding: 0 0.5em;
}
