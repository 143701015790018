.container {
}

.notice {
  padding: 1em;
  background-color: var(--color-surface-green);
  color: #fff;
  border-radius: 8px;
  margin-bottom: 1em;
}
