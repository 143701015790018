.container {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}

.loader {
  height: 40px;
  width: 80px;
  margin-bottom: 1em;
}

.label {
  font-size: 1.2em;
  font-weight: bold;
}

.subLabel {
  opacity: 0.5;
  font-size: 1em;
  margin-top: 0.5em;
}
