.container {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-end;
}

.input {
  background-color: var(--color-input-bg);
  color: var(--color-input-text);
  border: 3px solid var(--color-input-border);
  border-radius: 8px;
  line-height: 1.418;
  padding: 0.25em 0.5em;
  
  font-size: 1em;
  box-shadow: none;
  resize: vertical;
}

.input::placeholder {
  color: var(--color-input-placeholder);
}

.charsLeft {
  display: block;
  line-height: 22px;
  color: var(--color-text-gray);
  text-align: right;
  width: 100%;
}

.input:focus {
  border: 3px solid var(--color-input-border-focus);
  outline: none;
  box-shadow: none;
}

.input:invalid:not(.fresh) {
  border: 3px solid var(--color-input-border-invalid);
  outline: none;
  box-shadow: none;
}
