.container {
  pointer-events: none;
  height: 100vh;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 100ms;
}

.container.visible {
  opacity: 1;
}

.label {
  font-weight: bold;
  font-size: 1em;
}
