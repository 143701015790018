.container {
  padding: 1em;
  border-radius: 12px;
  border: 3px solid var(--color-border);
  background-color: var(--color-card-bg);
}

.header {
  display: flex;
  flex-flow: column nowrap;
  margin-bottom: 1em;
}

.searchCount {
  margin-top: 0.25em;
  font-size: 0.9em;
}

.list {
  list-style: none;
}

.listItem {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;

  padding-bottom: 0.5em;
}

.listItem:not(:last-child) {
  border-bottom: 1px solid var(--color-border);
  margin-bottom: 0.5em;
}

.itemName {
}

.itemDescription {
  /* font-size: 0.9em; */
}
