.container {
  background-color: var(--color-card-bg);
  padding: 1em;
  border: 3px solid var(--color-border);
  border-radius: 12px;
}

.formGroup {
  display: flex;
  flex-flow: column nowrap;
  margin-bottom: 1em;
}

.formGroup > label {
  font-weight: bold;
  margin-bottom: 0.25em;
}
