.row {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  width: 100%;
}

.row > *:not(:last-child) {
  margin-right: 0.25em;
}

.input {
  width: 100%;
}

.editButton {
  background-color: transparent;
  outline: none;
  border: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  opacity: 0.7;
  cursor: pointer;
  margin-left: 0.25em;
  mix-blend-mode: difference;
}

.editButton > svg {
  width: 24px;
  height: 24px;
}

.editButton:hover {
  opacity: 1;
}
