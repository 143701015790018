.transitionGroup {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(120deg, var(--color-login-gradient-1), var(--color-login-gradient-2));
}

.container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;

  color: #fff;

  transition: opacity 80ms;
}

/* Like container but for the children passed to the Auth component */
.children {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background-color: var(--color-bg);

  transition: opacity 80ms;
}

.signInActions {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}

.signInButton {
  font-size: 1em;
  
  font-weight: bold;
  padding: 0.5em 0.75em;
  border: none;
  outline: none;
  background-color: var(--color-d);
  color: #fff;
  border-radius: 6px;
}

.signInLogo {
  height: 100px;
  width: 200px;
  margin-bottom: 2em;
}

/* Screen transition group classes */
.enter {
  opacity: 0;
}
.enterActive {
  opacity: 0;
}
.enterDone {
  opacity: 1;
}

.exit {
  opacity: 1;
}
.exitActive {
  opacity: 0;
}
.exitDone {
  opacity: 0;
}
