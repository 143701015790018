.container {
}

.header {
  margin-bottom: 1em;
}

.container > section {
  margin-bottom: 1em;
}

.roles {
  padding: 1em;
  border: 3px solid var(--color-border);
  border-radius: 12px;
  background-color: var(--color-card-bg);
}

.rolesList {
  list-style: none;
}

.rolesList > li {
  padding: 0.5em 0;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.rolesList > li:not(:last-child) {
  border-bottom: 1px solid var(--color-border);
}

.addRole {
  display: flex;
  align-items: center;
  margin-top: 1em;
}

.addRole > *:not(:last-child) {
  margin-right: 0.25em;
}

.removeRoleButton {
  margin-right: 1em;
}
